import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import thunkMiddleware from "redux-thunk"
import {
  mainReducer,
  profileReducer,
  echoModuleReducer,
  virtualReducer,
  chartDataReducer,
  chartFirstDateReducer,
  handbooksReducer,
} from "shared"

const rootReducer = combineReducers({
  main: mainReducer,
  profile: profileReducer,
  echoModule: echoModuleReducer,
  virtualSensor: virtualReducer,
  chartData: combineReducers({ data: chartDataReducer, firstDate: chartFirstDateReducer }),
  handbooks: handbooksReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

// Типы для redux devtools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export type RootStateTypes = ReturnType<typeof rootReducer>

export { store }

import { EEventName, EProtocol } from "shared"

export enum JournalEventActionTypes {
  SET_JOURNAL_EVENT = "SET_JOURNAL_EVENT",
  SET_JOURNAL_EVENT_REQUEST = "SET_JOURNAL_EVENT_REQUEST",
  SET_JOURNAL_EVENT_ERROR = "SET_JOURNAL_EVENT_ERROR",
}

export enum EJournalEventType {
  SYSTEM_EVENT = "system_event",
  SYSTEM_RECOMMENDATIONS = "system_recommendation",
  ERROR = "error",
}

// Типы отдельного события
interface IJournalEvent {
  id: number
  module: string
  module_name: string
  equipment_name: string
  event_type: EJournalEventType
  event_name: EEventName
  time: string
  message: string
  error_code: number
  protocol: EProtocol
}

// Типы данных для таблицы журнала событий
interface IJournalEventList {
  results: IJournalEvent[]
  count: number
}

export type { IJournalEvent, IJournalEventList }

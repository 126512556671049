import {
  createUserActions,
  createApplicationStartActions,
  createEchoModuleActions,
  createChartDataActions,
  createChartFirstDateActions,
  createVirtualSensorsActions,
  createServerNodesActions,
  createHandbooksActions,
} from "shared"

import {
  userService,
  echoModuleService,
  virtualSensorsService,
  chartDataService,
  chartFirstDateService,
  serverNodesService,
  handbooksService,
} from "../../services"

/* Shared actions */
const userActions = createUserActions(userService)
const appStartActions = createApplicationStartActions(userService, handbooksService)
const echoModuleActions = createEchoModuleActions(echoModuleService)
const virtualSensorsActions = createVirtualSensorsActions(virtualSensorsService)
const chartDataActions = createChartDataActions(chartDataService)
const chartFirstDateActions = createChartFirstDateActions(chartFirstDateService)
const serverNodesActions = createServerNodesActions(serverNodesService)
const handbooksActions = createHandbooksActions(handbooksService)

const Actions = {
  ...userActions,
  ...appStartActions,
  ...echoModuleActions,
  ...virtualSensorsActions,
  ...chartDataActions,
  ...chartFirstDateActions,
  ...serverNodesActions,
  ...handbooksActions,
}

export default Actions

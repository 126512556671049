import { EProtocol } from "shared"

interface IDateTimeRange {
  lower: string
  upper: string
  bounds: { lower: string; upper: string }
}

interface IEditingRecommendationProps {
  comment?: string
  visible: boolean
}

interface IRecPlaceholders {
  warning_message: string
  abnormal_patterns_message: string
  action_message: string
  source: string
}

interface IBaseRecommendationField {
  id: string
  equipment: number
  equipment_name: string
  name: string
  description: string
  time: string
  period: IDateTimeRange
  type: ERecommendationType
  checked_by: string
  comment: string
  checked_at: string
  visible: boolean
  placeholders: IRecPlaceholders
}

interface IRecommendation extends IBaseRecommendationField {
  protocol: EProtocol
  item_id: string
  item_name: string
}

interface IEchoRecommendation extends IBaseRecommendationField {
  sensor: number
  module: string
  module_name: string
  org: string
}

interface IModbusRecommendation extends IBaseRecommendationField {
  gateway: string
  server: number
  command: number
  command_name: string
  org: string
}

interface IOPCRecommendation extends IBaseRecommendationField {
  gateway: string
  server: number
  node: number
  node_name: string
  org: string
}

interface IEchoRecommendationList {
  results: IEchoRecommendation[]
  count: number
}

interface IRecommendationList {
  results: IRecommendation[]
  count: number
}

type TMultipleEditingRecommendation = {
  id: number
  protocol: EProtocol
  visible: boolean
}[]

export enum ERecommendationTypeTypes {
  SYSTEM_RECOMMENDATION = "system_recommendation",
}

export enum ERecommendationType {
  FAULT = "fault",
  RECOMMENDATION = "recommendation",
  SETPOINTS = "setpoints",
}

interface IHeuristicVAData {
  frequency: number
  amplitudes: number
}
interface IHeuristicVALabel {
  label: string
  name: string
  description: string
  defect: string
}

interface IHeuristicVAFeature {
  id: number
  data: IHeuristicVAData[]
  label: IHeuristicVALabel
}

interface IHeuristicVA {
  features: IHeuristicVAFeature[]
  underlay_data: IHeuristicVAData[]
}

export type {
  IEchoRecommendation,
  IModbusRecommendation,
  IOPCRecommendation,
  IEditingRecommendationProps,
  IRecommendation,
  IEchoRecommendationList,
  IRecommendationList,
  TMultipleEditingRecommendation,
  IHeuristicVAData,
  IHeuristicVALabel,
  IHeuristicVAFeature,
  IHeuristicVA,
}

import { useCallback, useEffect, useMemo, useState } from "react"
import { EchoWSController, EEquipmentUserMode, EWSMessageTypes, IWSEquipmentData, useLatest } from "shared"
import { SelectChangeEvent } from "@mui/material"

import { recsAPI } from "@api"
import { useEquipmentProcessingAPI } from "./api"

export type TChangeUserMode = (event: SelectChangeEvent<EEquipmentUserMode>) => void

interface IUseUserMode {
  ws: EchoWSController
  equipmentId: number
  userMode?: EEquipmentUserMode
}

interface IUseUserModeReturn {
  userMode: EEquipmentUserMode
  changeUserMode: TChangeUserMode
  isEqPatchPending: boolean
}

export const useUserMode = ({
  ws,
  equipmentId,
  userMode = EEquipmentUserMode.IN_OPERATION,
}: IUseUserMode): IUseUserModeReturn => {
  const [equipmentUserMode, setEquipmentUserMode] = useState<EEquipmentUserMode>(userMode)

  const { patchEquipment, isEqPatchPending } = useEquipmentProcessingAPI()

  const changeUserMode = useCallback(
    ({ target: { value } }: SelectChangeEvent<EEquipmentUserMode>): void => {
      patchEquipment(
        equipmentId,

        { user_mode: value },

        () => {
          setEquipmentUserMode(value as EEquipmentUserMode)

          if (value !== EEquipmentUserMode.IN_OPERATION) return

          recsAPI.getLastRec({ params: { equipment: equipmentId, visible: true } })
        }
      )
    },
    [equipmentId]
  )

  const equipmentUserModeRef = useLatest({
    setEquipmentUserMode,
    equipmentId,
  })

  const observerObject = useMemo(
    () => ({
      cb: ({ id, user_mode }: IWSEquipmentData): void => {
        const { setEquipmentUserMode, equipmentId } = equipmentUserModeRef.current

        if (id !== equipmentId) return

        setEquipmentUserMode(user_mode)
      },
      type: EWSMessageTypes.EQUIPMENT,
    }),
    []
  )

  useEffect(() => {
    if (equipmentUserMode === userMode) return

    setEquipmentUserMode(userMode)
  }, [equipmentId, userMode])

  useEffect(() => {
    ws.on(observerObject)

    return () => {
      ws.off(observerObject)
    }
  }, [])

  return { userMode: equipmentUserMode, changeUserMode, isEqPatchPending }
}

import {
  userService as sharedUserService,
  echoModuleService as sharedEchoModuleService,
  chartDataService as sharedChartDataService,
  chartFirstDateService as sharedChartFirstDateService,
  serverNodesService as sharedServerNodesService,
  virtualSensorsService as sharedVirtualSensorsService,
  handbooksService as sharedHandbooksService,
  $AXIOS,
} from "shared"

/* Shared */
export const userService = sharedUserService($AXIOS)
export const echoModuleService = sharedEchoModuleService($AXIOS)
export const chartDataService = sharedChartDataService($AXIOS)
export const chartFirstDateService = sharedChartFirstDateService($AXIOS)
export const serverNodesService = sharedServerNodesService($AXIOS)
export const virtualSensorsService = sharedVirtualSensorsService($AXIOS)
export const handbooksService = sharedHandbooksService($AXIOS)

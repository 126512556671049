import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { EWSMessageTypes, EchoWSController, IWSModbusData, IWSOpcData } from "shared"

import { TEquipmentIndicator } from "@types"

export function useEquipmentIndicatorValues(
  equipmentIndicatorList: TEquipmentIndicator[],
  ws: EchoWSController
): TEquipmentIndicator[] {
  const [wsConnectedEquipmentIndicatorList, setWSConnectedEquipmentIndicatorList] =
    useState<TEquipmentIndicator[]>(equipmentIndicatorList)

  const equipmentIndicatorIdsRef = useRef<number[]>(equipmentIndicatorList.map(({ id }) => id))
  const WSDataBufferRef = useRef<Record<string, number>>({})

  const intervalId = useRef<NodeJS.Timer | null>(null)

  const setWSIndicators = useCallback(() => {
    if (!Object.keys(WSDataBufferRef.current).length) return

    setWSConnectedEquipmentIndicatorList((prevState) =>
      prevState.map((item) => {
        const newValue = WSDataBufferRef.current[item.id]

        return { ...item, value: newValue ?? item.value }
      })
    )

    WSDataBufferRef.current = {}
  }, [])

  const observerObjects = useMemo(
    () => [
      {
        cb: (data: IWSModbusData): void => {
          if (!equipmentIndicatorIdsRef.current.includes(data.command)) return

          WSDataBufferRef.current[data.command] = data.value
        },
        type: EWSMessageTypes.MODBUS_DATA,
      },
      {
        cb: (data: IWSOpcData): void => {
          if (!equipmentIndicatorIdsRef.current.includes(data.node)) return

          WSDataBufferRef.current[data.node] = data.value
        },
        type: EWSMessageTypes.OPC_DATA,
      },
    ],
    []
  )

  useEffect(() => {
    ws.on(observerObjects)
    intervalId.current = setInterval(() => setWSIndicators(), 1000)

    return () => {
      ws.off(observerObjects)
      intervalId.current && clearInterval(intervalId.current)
    }
  }, [])

  useEffect(() => {
    setWSConnectedEquipmentIndicatorList(equipmentIndicatorList)
    equipmentIndicatorIdsRef.current = equipmentIndicatorList.map(({ id }) => id)

    if (equipmentIndicatorList.length === 0) {
      ws.off(observerObjects)
      intervalId.current && clearInterval(intervalId.current)
      intervalId.current = null
    } else if (!intervalId.current) {
      ws.on(observerObjects)
      intervalId.current = setInterval(() => setWSIndicators(), 1000)
    }
  }, [equipmentIndicatorList])

  return wsConnectedEquipmentIndicatorList
}

import { equipmentProcessingAPI } from "@api"
import { useCallback, useState } from "react"
import { IEquipmentProcessingForm, useRestAPIReducer } from "shared"

type TPatchEquipment = (equipmentId: number, payload: IEquipmentProcessingForm, onSuccess: () => void) => Promise<void>

interface IUseEquipmentProcessingAPI {
  patchEquipment: TPatchEquipment
  isEqPatchPending: boolean
}

export const useEquipmentProcessingAPI = (): IUseEquipmentProcessingAPI => {
  const [isPending, setPending] = useState(false)

  const patchEquipment: TPatchEquipment = useCallback(
    (equipmentId, payload, onSuccess) =>
      equipmentProcessingAPI.patchEquipment({ params: { equipmentId }, payload, onSuccess, onPending: setPending }),
    []
  )

  return {
    patchEquipment,
    isEqPatchPending: isPending,
  }
}

import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"

import Actions from "../../store/actions"

// eslint-disable-next-line
const useTypeActionsHook = () => {
  const dispatch = useDispatch()
  return bindActionCreators(Actions, dispatch)
}

export { useTypeActionsHook }

import { useCallback, useEffect } from "react"

import { operatingTimeAPI } from "@api"
import { ERequestStatus, IEquipmentOperatingTime, IEquipmentOperatingTimePatch, useRestAPIReducer } from "shared"

type TGetOperatingTime = (equipmentId: number) => Promise<void>
type TPatchEquipmentOperatingTime = (equipmentId: number, payload: IEquipmentOperatingTimePatch) => Promise<void>
type TPostEquipmentOperatingTime = (equipmentId: number, payload: Partial<IEquipmentOperatingTime>) => Promise<void>

export type TOpTimeRequests = {
  getOperatingTime: TGetOperatingTime
  patchEquipmentOperatingTime: TPatchEquipmentOperatingTime
  postEquipmentOperatingTime: TPostEquipmentOperatingTime
}

interface IUseOperatingTimeAPIReturn {
  requests: TOpTimeRequests
  equipmentOperatingTime: IEquipmentOperatingTime | null
  isPending: boolean
  isError: boolean
}

export const useOperatingTimeAPI = (equipmentId: number): IUseOperatingTimeAPIReturn => {
  const [{ data: equipmentOperatingTime, status }, actions] = useRestAPIReducer<IEquipmentOperatingTime | null>(null)

  const getOperatingTime: TGetOperatingTime = useCallback(
    (equipmentId) => operatingTimeAPI.getEquipmentOperatingTime({ params: { equipmentId } }, actions),
    []
  )

  const patchEquipmentOperatingTime: TPatchEquipmentOperatingTime = useCallback(
    (equipmentId, payload) =>
      operatingTimeAPI.patchEquipmentOperatingTime({
        params: { equipmentId },
        payload,
        onSuccess: () => getOperatingTime(equipmentId),
      }),
    []
  )

  const postEquipmentOperatingTime: TPostEquipmentOperatingTime = useCallback(
    (equipmentId, payload) =>
      operatingTimeAPI.postEquipmentOperatingTime({ payload, onSuccess: () => getOperatingTime(equipmentId) }),
    []
  )

  useEffect(() => {
    getOperatingTime(equipmentId)
  }, [equipmentId])

  return {
    requests: { getOperatingTime, patchEquipmentOperatingTime, postEquipmentOperatingTime },
    equipmentOperatingTime,
    isPending: status === ERequestStatus.PENDING,
    isError: status === ERequestStatus.ERROR,
  }
}

import { useEffect, useMemo, useState } from "react"
import { EchoWSController, EEquipmentConditionTypes, EWSMessageTypes, IWSEquipmentData, useLatest } from "shared"

export type TEquipmentCondition = EEquipmentConditionTypes | null

interface IUseGetEquipmentCondition {
  ws: EchoWSController
  equipmentId: number
  condition?: TEquipmentCondition
}

export const useGetEquipmentCondition = ({
  ws,
  equipmentId,
  condition = null,
}: IUseGetEquipmentCondition): TEquipmentCondition => {
  const [equipmentCondition, setEquipmentCondition] = useState<TEquipmentCondition>(condition)

  const equipmentConditionRef = useLatest({
    setEquipmentCondition,
    equipmentId,
  })

  const observerObject = useMemo(
    () => ({
      cb: ({ id, condition }: IWSEquipmentData): void => {
        const { setEquipmentCondition, equipmentId } = equipmentConditionRef.current

        if (id !== equipmentId) return

        setEquipmentCondition(condition)
      },
      type: EWSMessageTypes.EQUIPMENT,
    }),
    []
  )

  useEffect(() => {
    ws.on(observerObject)

    return () => ws.off(observerObject)
  }, [])

  useEffect(() => {
    if (equipmentCondition === condition) return

    setEquipmentCondition(condition)
  }, [equipmentId, condition])

  return equipmentCondition
}

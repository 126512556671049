import { useCallback, useEffect, useState } from "react"
import { IEquipmentShort } from "shared"

import { equipmentAPI } from "@api"

interface IUseEquipmentShortList {
  equipmentShortList: IEquipmentShort[]
  isPending: boolean
}

export const useEquipmentShortListAPI = (): IUseEquipmentShortList => {
  const [equipmentShortList, setEquipmentShortList] = useState<IEquipmentShort[]>([])
  const [isPending, setIsPending] = useState(false)

  const getEquipmentShortList = useCallback(() => {
    equipmentAPI.getEquipmentShortList({
      onSuccess: (data) => {
        setEquipmentShortList(data)
      },
      onPending: (isPending) => {
        setIsPending(isPending)
      },
      onError: () => {
        console.log("Ошибка получения короткого списка оборудований")
      },
    })
  }, [])

  useEffect(() => {
    getEquipmentShortList()
  }, [])

  return {
    equipmentShortList,
    isPending,
  }
}

import { TRequestParamsId, useDataGrid } from "shared"
import { useCallback, useEffect, useState } from "react"

import { IEquipmentIndicatorsList } from "@types"
import { indicatorServiceAPI } from "@api"

interface IUseEquipmentIndicatorsList {
  equipmentIndicatorsList: IEquipmentIndicatorsList
  equipmentIndicatorsListRequest: boolean
}

export const useEquipmentIndicatorsListAPI = (equipmentId: TRequestParamsId): IUseEquipmentIndicatorsList => {
  const [equipmentIndicatorsList, setEquipmentIndicatorsList] = useState<IEquipmentIndicatorsList>({
    results: [],
    count: 0,
  })
  const [equipmentIndicatorsListRequest, setEquipmentIndicatorsListRequest] = useState(false)

  const getEquipmentIndicatorsList = useCallback(
    (params?: string) => {
      indicatorServiceAPI.getEquipmentIndicatorsList({
        params: { id: equipmentId, reqString: params },
        onSuccess: (data) => {
          setEquipmentIndicatorsList(data)
        },
        onPending: (isPending) => {
          setEquipmentIndicatorsListRequest(isPending)
        },
        onError: () => {
          console.log("Ошибка получения общих показателей оборудования")
        },
      })
    },
    [equipmentId]
  )

  const dataGridMethods = useDataGrid(getEquipmentIndicatorsList, {
    customDefaultValues: { unassignedSelect: "true" },
    customFieldNames: { unassignedSelect: "is_selected" },
  })

  useEffect(() => {
    getEquipmentIndicatorsList(dataGridMethods.queryString)
  }, [getEquipmentIndicatorsList])

  return { equipmentIndicatorsList, equipmentIndicatorsListRequest }
}

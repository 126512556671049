import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { EWSMessageTypes, EchoWSController, useLatest } from "shared"

import { recsAPI } from "@api"
import { IRecommendation } from "@types"

export enum ERecUpdateStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  DELAYED = "DELAYED",
}

interface IUseLastRecommendationAPI {
  ws: EchoWSController
  equipmentId: number
  modalBoundUpdateStatus?: React.MutableRefObject<ERecUpdateStatus>
}

interface ILastRecommendationAPI {
  lastRecommendation: IRecommendation | null
  isPending: boolean
  getLastRecommendation(): void
}

export const useLastRecommendationAPI = ({
  ws,
  equipmentId,
  modalBoundUpdateStatus,
}: IUseLastRecommendationAPI): ILastRecommendationAPI => {
  const [isPending, setIsPending] = useState(true)
  const [lastRecommendation, setLastRecommendation] = useState<IRecommendation | null>(null)

  const getLastRecommendation = useCallback((): void => {
    recsAPI.getLastRec({
      params: { equipment: equipmentId, visible: true },
      onSuccess: (data) => {
        setLastRecommendation(data)
      },
      onPending: (_isPending) => {
        setIsPending(_isPending)
      },
      onError: () => {
        console.log("Ошибка получения последеней рекомендации")
      },
    })
  }, [equipmentId])

  const recRef = useLatest({ lastRecommendation, equipmentId, getLastRecommendation })

  const observerObject = useMemo(
    () => ({
      cb: (data: IRecommendation): void => {
        const { lastRecommendation, equipmentId, getLastRecommendation } = recRef.current

        if (
          !isValidWSRecMessage({ newRec: data, currentRec: lastRecommendation, equipmentId }) ||
          modalBoundUpdateStatus?.current === ERecUpdateStatus.DELAYED
        )
          return

        if (modalBoundUpdateStatus?.current === ERecUpdateStatus.DISABLED) {
          modalBoundUpdateStatus.current = ERecUpdateStatus.DELAYED
          return
        }

        getLastRecommendation()
      },
      type: [
        EWSMessageTypes.MODULE_RECOMMENDATION,
        EWSMessageTypes.EXTERNAL_MODBUS_RECOMMENDATION,
        EWSMessageTypes.EXTERNAL_OPC_RECOMMENDATION,
      ],
    }),
    []
  )

  useEffect(() => {
    ws.on(observerObject)

    return () => ws.off(observerObject)
  }, [])

  return { isPending, lastRecommendation, getLastRecommendation }
}

function isValidWSRecMessage({
  newRec,
  currentRec,
  equipmentId,
}: {
  newRec: IRecommendation
  currentRec: IRecommendation | null
  equipmentId: number
}): boolean {
  const belongsToThisEquipment = newRec.equipment === equipmentId
  const isSameRecUpdate = currentRec?.id === newRec.id
  const hasVisibilityChanged = isSameRecUpdate && currentRec?.visible !== newRec.visible
  const hasPeriodChanged =
    currentRec === null
      ? false
      : currentRec?.period?.upper !== newRec?.period.upper ||
        (isSameRecUpdate && currentRec?.period.lower !== newRec?.period.lower)

  if (!belongsToThisEquipment) return false

  return hasVisibilityChanged || hasPeriodChanged
}
